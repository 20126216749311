import React from "react";
import classes from "./Section.module.scss";
import CheckBox from "@mui/material/Checkbox";
export default function Section(props) {
  return (
    <div
      style={{ wordBreak: "break-all" }}
      dir="auto"
      className={`  ${classes.section}`}
    >
      <div className="row  px-0 mx-0">
        {props.checkBox && (
          <div
            onClick={() => {
              props.onClick();
            }}
            className="w-auto px-0 pe-2 d-inline"
          >
            {" "}
            <CheckBox
              checked={props.checked}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        )}
        <div className="w-auto px-0 d-inline">
          <h1>{props.title}</h1>
        </div>
      </div>
      <div
        className={` ${props.checkBox && "ps-4 "} ${
          props.border && classes.border
        } `}
      >
        <p className={`${props.checkBox ? "ps-4" : "ps-1"}`}>{props.text}</p>
      </div>
    </div>
  );
}
